import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
// import { useStaticQuery, graphql } from "gatsby"
import { get, isEmpty } from 'lodash';
import Autocomplete from 'react-google-autocomplete';
import Button from '../components/atoms/Button/Button';
import Checkbox from '../components/atoms/Checkbox/Checkbox';
import Container from '../components/atoms/Container/Container';
import CurrencyFormatter from '../components/atoms/CurrencyFormatter/CurrencyFormatter';
import Link from '../components/atoms/Link/Link';
import Loader from '../components/atoms/Loader/Loader';
import CartShippingOptions from '../components/molecules/CartShippingOptions/CartShippingOptions';
import CartSummary from '../components/molecules/CartSummary/CartSummary';
import Cart from '../components/organisms/Cart/Cart';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import AuthContext from '../context/AuthProvider';
import CartContext from '../context/CartProvider';
import { bcApi } from '../helpers/bigcommerce';
import {
  getStorage,
  rewriteShippingOptionLabel,
  setStorage,
  validateEmail
} from '../helpers/general';
import { predictAddress } from '../helpers/geoscape'; // fetchAddressType
import recommend from '@algolia/recommend';
import { useRecommendations } from '@algolia/recommend-react';
import {
  Box,
  Link as ChakraLink,
  Flex,
  Input,
  Select,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  ModalBody,
  SimpleGrid,
  ModalOverlay,
  ModalContent,
  Modal,
  Grid
} from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import AutoShip from '../components/molecules/AutoShip/AutoShip';
import FrequentProducts from '../components/molecules/FrequentsProduct/FrequentProducts';
import { requiredAddressFields } from '../helpers/constants/confirm-order';
import { checkIfExisting, subscribe as klaviyoSubscribe } from 'helpers/klaviyo';
import useDebounce from 'hooks/useDebounce';
import { useParams } from 'hooks/useParams';
import useSegment from 'hooks/useSegment';
import { useCartStore } from 'stores/cart';
import * as pcStyles from './confirm-order.module.scss';
import * as mpsStyles from './confirm-order.mps.module.scss';
import { storeStyle, isMadPawsStore } from '../common/util';
import Icon from '../components/atoms/Icon/Icon';
import LoginForm from '../components/molecules/LoginForm/LoginForm';
import { useGlobalStore } from 'stores/global';

const recommendClient = recommend(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
);

const indexName = process.env.GATSBY_ALGOLIA_INDEX_NAME;
const styles = storeStyle({ mpsStyles, pcStyles });
const ConfirmOrderPage = () => {
  const { track, identify, identifyManual } = useSegment();
  const cartCtx = useContext(CartContext);

  const toast = useToast();
  const { signature } = useCartStore(state => state);
  const { isAutoShip } = useGlobalStore(state => state);
  const cartFetched = get(cartCtx, 'state.cartFetched', false);
  const cart = get(cartCtx, 'state.cart', {});
  const searchRef = useRef(null);
  const checkout = get(cartCtx, 'state.checkout', {});
  const numberItems = get(cartCtx, 'state.cart.numberItems');
  /* SUPPORT FOR LOCALISR AND CnC */
  // const ctxStockAvailability = get(cartCtx, 'state.stockAvailability');
  // const ctxSelectedStore = get(cartCtx, 'state.selectedStore');
  const cartShippingMethod = get(cartCtx, 'state.shippingMethod', 'delivery');
  const ctxReloadCheckout = get(cartCtx, 'initCheckout');
  const [fetchingCheckout, setFetchingCheckout] = useState(false);
  const [settingConsignment, setSettingConsignment] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  // const [openLogin, setOpenLogin] = useState(false);

  const checkoutError = useParams('cherr');
  const triggerStep = useParams('step');

  const algoliaQueryId = getStorage('algoliaQueryId');
  const authCtx = useContext(AuthContext);
  const isLoggedIn = get(authCtx, 'state.isLoggedIn', false);
  const userChecked = authCtx && authCtx.state.userChecked;
  const handleLogin = authCtx && authCtx.login;

  const accountDetails = get(authCtx, 'state.object', {});
  const refreshUser = authCtx && authCtx.refreshData;

  const storeKey = '__jammcd';
  const stepKey = '__jammstep';
  const stateKey = '__jammqs';
  const [init, setInit] = useState(false);

  const [deliveryInit, setDeliveryInit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { isOpen: hasComment, onOpen: onComment } = useDisclosure();
  const [shippingRequired, setShippingRequired] = useState(true);
  /* SUPPORT FOR LOCALISR AND CnC */
  const ccEligible = false;
  const storedStep = getStorage(stepKey);
  const [cartStep, setCartStep] = useState(storedStep && storedStep !== 'cart' ? false : true);
  const setStep =
    (storedStep && storedStep === 'account' && isLoggedIn ? 'shipping' : storedStep) || 'cart';
  const [currentStep, setCurrentStep] = useState(setStep);
  const [nextStepValid, setNextStepValid] = useState(!ccEligible);
  const [allProductsAvailable, setAvailable] = useState(true);
  const [methodBeingSet, settingMethod] = useState(false);

  const [checkoutEmail, setCheckoutEmail] = useState((isLoggedIn && accountDetails.email) || null);
  // const [manualShippingAddress, setManualShippingAddress] = useState(true);
  // const [shippingAddressResults, setShippingAddressResults] = useState(false);
  // const [shippingAddressMessage, setShippingAddressMessage] = useState(false);
  // const [shippingAddressId, setShippingAddressId] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({
    billingSameAsShipping: true,
    country_code: 'AU'
  });

  const debouncedShipping = useDebounce(shippingAddress, 1000);

  const [consignment, setConsignment] = useState({});
  const [shippingOptionMessage, setShippingOptionMessage] = useState(
    'Please enter your shipping address to calculate available options'
  );
  const [shippingOptions, setShippingOptions] = useState([]);
  const [fieldMissing, setFieldMissing] = useState([]);

  const [fetchingOptions, setFetchingOptions] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState(false);

  const [place, setPlace] = useState({
    place: undefined,
    billingOnly: false,
    onDemand: false
  });

  const [shippingMethod, setShippingMethod] = useState(false);

  // const [manualBillingAddress, setManualBillingAddress] = useState(true);
  // const [billingAddressResults, setBillingAddressResults] = useState(false);
  // const [billingAddressMessage, setBillingAddressMessage] = useState(false);
  const [billingAddress, setBillingAddress] = useState({
    country_code: 'AU'
  });
  const [isBillingAddressValid, setBillingAddressValid] = useState(false);
  const [countries, setCountries] = useState([]);
  const [shippingCountryStates, setShippingCountryStates] = useState([]);
  const [billingCountryStates, setBillingCountryStates] = useState([]);
  const [confirmingCheckoutData, setConfirmingCheckoutData] = useState(false);
  const [checkoutFailMessage, setCheckoutFailMessage] = useState([]);

  //Need to remove skus INS, REPLY, and SIGREQ from recommendations
  const { recommendations } = useRecommendations({
    model: 'related-products',
    recommendClient,
    indexName,
    objectIDs:
      cart.lineItems?.physical_items
        ?.filter(item => !['INS', 'REPLY', 'SIGREQ'].includes(item.sku))
        .map(item => String(item.product_id)) ?? [],
    maxRecommendations: 3
  });

  const dataQuery = useStaticQuery(graphql`
    query {
      allBigCommerceCountries {
        edges {
          node {
            country
            country_iso2
            states {
              resource
            }
          }
        }
      }
    }
  `);

  const scrollToSection = sectionId => {
    setTimeout(() => {
      const sTop = document.getElementById(sectionId)?.offsetTop - 112;

      if (sTop)
        window.scrollTo({
          top: sTop,
          left: 0,
          behavior: 'smooth'
        });
    }, 50);
  };

  const toggleBillingAddressCnC = async () => {
    toggleNextStep('billing');
    try {
      const _newState = await ctxReloadCheckout();
      setShippingAddress(_newState.checkout.consignments[0].shipping_address);
    } catch (_e) {
      console.log(_e);
    }
  };

  const productTrackData = () => {
    return checkout?.cart?.line_items?.physical_items?.map(item => {
      const product = cart?.productInfo[item.product_id];
      const isMedication = product?.custom_fields?.find(
        field => field.name === 'Product Type' && field.value === 'Medication'
      );
      return {
        name: item.name,
        sku: item.sku,
        quantity: item.quantity,
        product_type: !!isMedication ? 'Medication' : 'OTC',
        price: item.list_price,
        variant: item.variant_id,
        image_url: item.image_url
      };
    });
  };
  const trackCheckout = () => {
    // add delay to ensure user is identified
    setTimeout(() => {
      track('Checkout Started', {
        affiliation: isMadPawsStore ? 'MPS' : 'PC',
        currency: cart?.currency?.code ?? 'AUD',
        cartId: cart?.cartId,

        coupon: checkout?.coupons?.map(coupon => coupon.code).join(','),
        discount: checkout?.cart?.discount_amount ?? 0,
        products: productTrackData()
      });
    }, 1000);
  };

  const toggleNextStep = async nextStep => {
    switch (nextStep) {
      case 'cart':
        setCartStep(true);
        setCurrentStep('cart');
        setStorage(stepKey, 'cart', true);
        scrollToSection('section-cart');
        break;

      case 'account':
        setCartStep(false);

        setStorage(
          stateKey,
          JSON.stringify({
            baseAmount: cart.baseAmount,
            items: cart.numberItems
          })
        );
        storeCheckoutData({ cartConfirmed: true });
        let isShippingRequired = shippingRequired;
        if (
          cart &&
          'physical_items' in cart.lineItems &&
          cart.lineItems.physical_items.length === 0 &&
          'gift_certificates' in cart.lineItems &&
          cart.lineItems.gift_certificates.length > 0
        ) {
          // DIGITAL ITEMS IN CART ONLY - No need for shipping
          isShippingRequired = false;
          setShippingRequired(isShippingRequired);
        }

        if (isLoggedIn) {
          identify();
          // console.log(checkout);
          if (checkout && checkout.cart && checkout.cart.customer_id === 0) {
            // console.log("Set customer ID on to Cart");
            bcApi(`carts/${checkout.id}`, 'PUT', {
              customer_id: accountDetails.id
            }).then(async () => {
              // console.log("Refresh checkout");
              await ctxReloadCheckout();
              if (isShippingRequired) {
                track('Checkout Step Completed', {
                  currency: 'AUD',
                  step: 1,
                  algolia_query_id: algoliaQueryId
                });
                toggleNextStep('shipping');
              } else {
                track('Checkout Step Completed', {
                  currency: 'AUD',
                  step: 1,
                  algolia_query_id: algoliaQueryId
                });
                toggleNextStep('billing');
              }
            });
          } else {
            if (isShippingRequired) {
              track('Checkout Step Completed', {
                currency: 'AUD',
                step: 1,
                algolia_query_id: algoliaQueryId
              });
              toggleNextStep('shipping');
            } else {
              track('Checkout Step Completed', {
                currency: 'AUD',
                step: 1,
                algolia_query_id: algoliaQueryId
              });
              toggleNextStep('billing');
            }
          }
          setCheckoutEmail(accountDetails.email);
          storeCheckoutData({ email: accountDetails.email });

          trackCheckout();
        } else {
          setCurrentStep('account');
          setStorage(stepKey, 'account', true);
          scrollToSection('section-account');
          identifyManual({
            id: 0,
            email: checkoutEmail
          });
        }

        track('Checkout Step Viewed', {
          currency: 'AUD',
          step: 1,
          affiliation: isMadPawsStore() ? 'MPS' : 'PC',
          products: productTrackData()
        });

        break;

      case 'shipping':
        track('Checkout Step Viewed', {
          currency: 'AUD',
          step: 2,
          affiliation: isMadPawsStore() ? 'MPS' : 'PC',
          products: productTrackData()
        });
        if (cartShippingMethod === 'collect') {
          toggleBillingAddressCnC();
          break;
        }
        if (!shippingRequired) {
          toggleNextStep('billing');
          break;
        }
        setCurrentStep('shipping');
        setStorage(stepKey, 'shipping', true);
        scrollToSection('section-shipping');
        if (!shippingAddress.state_or_province && !shippingAddress.country_code) {
          fetchStates({ target: { value: 'AU' } }, 'shipping');
        } else if (shippingAddress.country_code) {
          fetchStates({ target: { value: shippingAddress.country_code } }, 'shipping');
        }
        await checkForShippingMethods();
        break;

      case 'billing':
        setCurrentStep('billing');
        setStorage(stepKey, 'billing', true);
        scrollToSection('section-billing');
        if (!billingAddress.state_or_province && !billingAddress.country_code) {
          fetchStates({ target: { value: 'AU' } }, 'billing');
        } else if (billingAddress.country_code) {
          fetchStates({ target: { value: billingAddress.country_code } }, 'billing');
        }
        setBillingAddressValid(validateRequiredAddressFields(billingAddress));
        break;

      case 'payment':
        setCurrentStep('payment');
        setStorage(stepKey, 'payment', true);
        scrollToSection('section-payment');

        track('Checkout Step Viewed', {
          currency: 'AUD',
          shippingMethod: shippingMethod?.description,
          step: 3,
          affiliation: isMadPawsStore() ? 'MPS' : 'PC',
          products: productTrackData()
        });
        break;

      default:
        break;
    }
  };

  const storeCheckoutData = obj => {
    const existingJSON = getStorage(storeKey) || JSON.stringify({});
    const existing = JSON.parse(existingJSON);
    const newSet = JSON.stringify({ ...existing, ...obj });
    setStorage(storeKey, newSet);
  };

  const removeCheckoutData = objKey => {
    const existingJSON = getStorage(storeKey) || JSON.stringify({});
    const existing = JSON.parse(existingJSON);
    delete existing[objKey];
    const newSet = JSON.stringify({ ...existing });
    setStorage(storeKey, newSet);
  };

  const setIsValid = res => {
    setNextStepValid(res);
  };

  const validateRequiredAddressFields = data => {
    let allValid = true;
    const emptyFields = [];
    requiredAddressFields.map(field => {
      if (!(field in data) || data[field].trim() === '') {
        allValid = false;
        emptyFields.push(field);
      }
      return true;
    });
    setFieldMissing(emptyFields);
    return allValid;
  };

  const handleGuestEmail = email => {
    // Validate email
    if (!validateEmail(email)) {
      setCheckoutEmail(null);
      removeCheckoutData('email');
      return false;
    }

    setCheckoutEmail(email);
    storeCheckoutData({ email });
  };
  const assignGuestToOrder = async () => {
    if (checkoutEmail) {
      // const existedAccount = await bcApi(
      //   `customers?email=${checkoutEmail}`,
      //   'GET',
      //   null,
      //   2
      // ).catch(error => console.error('Error:', error));

      // if (existedAccount.status === 200 && existedAccount.response.length > 0) {
      //   setStorage('forcedPage', `${window.location.origin}/confirm-order?step=shipping`, true);
      //   setOpenLogin(true);
      //   return;
      // }

      if (subscribe) {
        const listId = process.env.GATSBY_KLAVIYO_NEWSLETTER;
        try {
          checkIfExisting(listId, checkoutEmail).then(response => {
            if (response.response.length === 0) {
              klaviyoSubscribe(listId, checkoutEmail).then(response => {
                if (response && 'status' in response && response.status === 200) {
                }
              });
            } else {
              console.log('Already subscribed');
            }
          });
        } catch (_) {
          console.error('Error subscribing to newsletter');
        }
      }

      identifyManual({
        id: 0,
        email: checkoutEmail
      });

      toggleNextStep('shipping');
      trackCheckout();
    }
  };

  const setPreviousAddress = async (type, addressId) => {
    if (accountDetails && addressId) {
      const addressDetails = accountDetails.addresses.find(
        address => address.id === parseInt(addressId)
      );
      const field = { ...addressDetails };
      document.getElementById(`${type}_first_name`).value = field.first_name;
      document.getElementById(`${type}_last_name`).value = field.last_name;
      document.getElementById(`${type}_company`).value = field.company;
      document.getElementById(`${type}_phone`).value = field.phone;
      document.getElementById(`${type}_address1`).value = field.address1;
      if (type !== 'shipping') {
        document.getElementById(`${type}_address2`).value = field.address2;
      }
      document.getElementById(`${type}_city`).value = field.city;
      document.getElementById(`${type}_state`).value = field.state_or_province;
      document.getElementById(`${type}_country_code`).value = field.country_code;
      document.getElementById(`${type}_postal_code`).value = field.postal_code;

      if (type === 'shipping') {
        const newFields = { ...shippingAddress, ...field };
        // setShippingAddressResults(false);
        storeCheckoutData({ shipping: newFields });
        setShippingAddress(newFields);
        // Attempt to get search address
        const suggestions = await predictAddress(`${field.address1}, ${field.city}`);
        let geoAddressId = false;
        if (suggestions && suggestions.type === 'results' && suggestions.results[0]) {
          geoAddressId = suggestions.results[0].id;
        }
        // setShippingAddressId(geoAddressId);
        setShippingMethod(false);
        removeCheckoutData('shippingMethod');
        await checkForShippingMethods(checkoutEmail, newFields, geoAddressId);

        if (!newFields.state_or_province && !newFields.country_code) {
          fetchStates({ target: { value: 'AU' } }, 'shipping');
        } else if (newFields.country_code) {
          fetchStates({ target: { value: newFields.country_code } }, 'shipping');
        }
      } else if (type === 'billing') {
        const newFields = { ...billingAddress, ...field };
        // setBillingAddressResults(false);
        setBillingAddress(newFields);
        storeCheckoutData({ billing: newFields });
        setBillingAddressValid(validateRequiredAddressFields(newFields));
      }
    }
  };

  const placeSelectHandler = (place, billingOnly = false) => {
    const addressComponentsMap = {};
    place?.address_components?.forEach(component => {
      if (component.types.includes('route')) {
        addressComponentsMap.route = component.long_name;
      } else if (component.types.includes('street_number')) {
        addressComponentsMap.streetNumber = component.long_name;
      } else if (component.types.includes('locality')) {
        addressComponentsMap.locality = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        addressComponentsMap.adminArea = component.long_name;
      } else if (component.types.includes('postal_code')) {
        addressComponentsMap.postalCode = component.long_name;
      } else if (component.types.includes('country')) {
        addressComponentsMap.country = component;
      } else if (component.types.includes('subpremise')) {
        addressComponentsMap.subpremise = component.long_name;
      }
    });

    const address1 = `${
      addressComponentsMap.subpremise ? `${addressComponentsMap.subpremise}/` : ''
    }${addressComponentsMap.streetNumber ?? ''} ${addressComponentsMap.route ?? ''}`;

    const newState = {
      address1,
      city: addressComponentsMap.locality ?? '',
      state_or_province: addressComponentsMap.adminArea ?? '',
      postal_code: addressComponentsMap.postalCode ?? '',
      country_code: addressComponentsMap.country?.short_name ?? ''
    };

    const newShipping = { ...shippingAddress, ...newState };
    const newBilling = { ...billingAddress, ...newState };

    if (billingOnly) {
      setBillingAddress(newBilling);
      storeCheckoutData({ billing: newBilling });
    } else {
      setShippingAddress(newShipping);
      setBillingAddress(newBilling);
      storeCheckoutData({ shipping: newShipping, billing: newBilling });
    }

    if (addressComponentsMap.country?.short_name) {
      fetchStates(
        { target: { value: addressComponentsMap.country?.short_name } },
        billingOnly ? 'billing' : 'shipping'
      );
    }
  };

  const handleShippingMethod = async (
    value,
    availableShippingOptions = [],
    currentConsignment = {}
  ) => {
    const selectedMethod =
      availableShippingOptions.length > 0
        ? availableShippingOptions.find(method => method.id === value)
        : shippingOptions?.find(method => method.id === value);

    if (!selectedMethod) {
      console.error('Selected method not found');
      settingMethod(false);
      return;
    } else {
      settingMethod(true);
    }

    const cartId = cart.cartId;
    const _consignment = 'id' in consignment ? consignment : currentConsignment;

    const useCheckoutEmail = checkoutEmail;
    const useShippingAddress = _consignment.shipping_address;

    const validate = validateRequiredAddressFields(useShippingAddress);
    if (!useCheckoutEmail || !validate || !cart || !('physical_items' in cart.lineItems)) {
      setShippingOptions([]);
      setShippingOptionMessage(
        'One or more fields are still required before available options can be calculated'
      );
      return;
    }

    const lineItems = [...cart.lineItems.physical_items, ...cart.lineItems.custom_items];
    const createPostRequestBody = (email, address, lineItems) => [
      {
        shipping_address: {
          email,
          first_name: address.first_name,
          last_name: address.last_name,
          company: address.company,
          address1: address.address1,
          city: address.city,
          country_code: address.country_code,
          state_or_province: address.state_or_province,
          state_or_province_code: address.state_or_province,
          postal_code: address.postal_code,
          phone: address.phone
        },
        line_items: lineItems.map(item => ({
          item_id: item.id,
          quantity: item.quantity
        }))
      }
    ];

    const requestBody = {
      shipping_option_id: selectedMethod.id
    };

    const postRequestBody = createPostRequestBody(useCheckoutEmail, useShippingAddress, lineItems);
    let selectShippingStatusError = null;
    let checkoutDataRes = null;
    try {
      const response = await bcApi(
        `checkouts/${cartId}/consignments/${_consignment.id}`,
        'PUT',
        requestBody
      );

      if (
        response.status === 200 &&
        response.response.data.consignments.length > 0 &&
        'selected_shipping_option' in response.response.data.consignments[0]
      ) {
        checkoutDataRes = response.response.data.consignments[0];
        setShippingMethod(selectedMethod);
        storeCheckoutData({ shippingMethod: selectedMethod });
        await cartCtx.fetchCart();
      }
    } catch (error) {
      console.error('Error setting shipping method:', error);
      if (error.response) {
        try {
          const postResponse = await bcApi(
            `checkouts/${cartId}/consignments`,
            'POST',
            postRequestBody[0]
          );

          if (
            postResponse.status === 200 &&
            postResponse.response.data.consignments.length > 0 &&
            'selected_shipping_option' in postResponse.response.data.consignments[0]
          ) {
            checkoutDataRes = postResponse.response.data.consignments[0];
            setShippingMethod(selectedMethod);
            storeCheckoutData({ shippingMethod: selectedMethod });
            await cartCtx.fetchCart();
          }
        } catch (postError) {
          console.error('Failed to create new consignment:', postError);
          selectShippingStatusError = {
            status: 'Error',
            msg: postError?.toString() || 'false'
          };
        }
      } else {
        console.error('API request failed:', error);
        selectShippingStatusError = {
          status: 'error',
          msg: error ? error?.toString() : 'false'
        };
      }
      if (selectShippingStatusError) {
        track('Checkout Error', {
          type: 'handleShippingMethod',
          ...selectShippingStatusError
        });
      }
    } finally {
      setTimeout(() => {
        settingMethod(false);
      }, 500);
    }
    return selectShippingStatusError ? false : checkoutDataRes;
  };

  const handleShippingAddress = (key, value) => {
    const field = {};
    field[key] = value;
    const newFields = { ...shippingAddress, ...field };

    // console.log(newFields);
    setShippingAddress(newFields);
    // setShippingMethod(false);
    // removeCheckoutData('shippingMethod'); // Theres a reason this was here, but causes issues elsewhere
    storeCheckoutData({ shipping: newFields });
  };

  const handleShippingToBilling = e => {
    handleShippingAddress('billingSameAsShipping', e.target.checked);
  };

  const checkForShippingMethods = async (newCheckoutEmail, newShippingAddress) => {
    const useCheckoutEmail = newCheckoutEmail || checkoutEmail;
    const useShippingAddress = newShippingAddress || shippingAddress;

    const validate = validateRequiredAddressFields(useShippingAddress);
    if (!useCheckoutEmail || !validate || !cart || !('physical_items' in cart.lineItems)) {
      setShippingOptions([]);
      setShippingOptionMessage(
        'One or more fields are still required before available options can be calculated'
      );
      return;
    }

    const createRequestBody = (email, address, lineItems) => [
      {
        shipping_address: {
          email,
          first_name: address.first_name,
          last_name: address.last_name,
          company: address.company,
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          country_code: address.country_code,
          state_or_province: address.state_or_province,
          state_or_province_code: address.state_or_province,
          postal_code: address.postal_code,
          phone: address.phone
        },
        line_items: lineItems.map(item => ({
          item_id: item.id,
          quantity: item.quantity
        }))
      }
    ];

    const getEndpointAndMethod = (cartId, consignment) => {
      if (consignment) {
        return {
          endpoint: `checkouts/${cartId}/consignments/${consignment.id}?include=consignments.available_shipping_options`,
          method: 'PUT'
        };
      } else {
        return {
          endpoint: `checkouts/${cartId}/consignments?include=consignments.available_shipping_options`,
          method: 'POST'
        };
      }
    };

    const filterShippingOptions = options => {
      if (cartShippingMethod === 'delivery') {
        return options.filter(
          option => option.type !== 'shipping_pickupinstore' && option.type !== 'freeshipping'
        );
      }
      return [];
    };

    const handleNoShippingOptions = response => {
      if (
        response.status === 200 &&
        response.response.data.consignments.length > 0 &&
        response.response.data.consignments[0].available_shipping_options.length === 0
      ) {
        setConsignment(response.response.data.consignments[0]);
        setShippingOptions([]);
        setFetchedOptions(true);
      } else {
        setConsignment({});
        setShippingOptions([]);
        setFetchedOptions(false);
      }
    };

    const handleShippingResponse = async response => {
      if (response.status === 200 && response.response.data.consignments.length > 0) {
        const newConsignment = response.response.data.consignments[0];
        setConsignment(newConsignment);

        const availableShippingOptions = filterShippingOptions(
          newConsignment.available_shipping_options
        );
        setShippingOptions(availableShippingOptions);
        setFetchedOptions(true);
        if (availableShippingOptions.length === 1) {
          // if had only one, then select it
          await handleShippingMethod(
            availableShippingOptions[0].id,
            availableShippingOptions,
            newConsignment
          );
        } else if (shippingMethod?.id) {
          // select previous method
          const selectedMethod = availableShippingOptions.find(
            method => method.id === shippingMethod.id
          );
          if (selectedMethod) {
            await handleShippingMethod(selectedMethod.id, availableShippingOptions, newConsignment);
          }
        }
      } else {
        handleNoShippingOptions(response);
      }
    };

    setFetchingOptions(true);
    setFetchedOptions(false);
    const cartId = cart.cartId;
    const consignment = get(checkout, 'consignments[0]');
    const lineItems = [...cart.lineItems.physical_items, ...cart.lineItems.custom_items];
    let requestBody = createRequestBody(useCheckoutEmail, useShippingAddress, lineItems);
    let { endpoint, method } = getEndpointAndMethod(cartId, consignment);
    // let endpoint = `checkouts/${cartId}/consignments?include=consignments.available_shipping_options`;
    // let method = 'POST';
    if (consignment) {
      requestBody = requestBody[0];
    }
    setSettingConsignment(true);
    const ensureStateUpdates = () => {
      setFetchingOptions(false);
      setSettingConsignment(false);
      // Additional safeguard: ensure states are set after a short delay if necessary
      setTimeout(() => {
        setFetchingOptions(false);
        setSettingConsignment(false);
      }, 100);
    };
    // console.log('consignment endpoint', endpoint, method, requestBody);
    try {
      const response = await bcApi(endpoint, method, requestBody);
      await handleShippingResponse(response);
    } catch (error) {
      console.error('Error fetching shipping methods:', error);
    } finally {
      ensureStateUpdates();
    }
  };

  const assignShippingToOrder = () => {
    // Validate we have all required fields
    if (!validateRequiredAddressFields(shippingAddress)) {
      // TODO: return meaningful error to user. This should be a formality here though as this is run as part of the shipping methods check.
      // console.log("Validation Shipping failed");
      return false;
    }
    setCheckoutFailMessage([]);
    if (shippingAddress.billingSameAsShipping) {
      const newBillingFields = { ...shippingAddress };
      delete newBillingFields.comments;
      delete newBillingFields.billingSameAsShipping;
      setBillingAddress(newBillingFields);
      storeCheckoutData({ billing: newBillingFields });
      setBillingAddressValid(true);
      toggleNextStep('payment');
      // console.log("Adding billing to order");
      assignBillingToOrder(newBillingFields);
    } else {
      // console.log("Toggling to billing");
      toggleNextStep('billing');
    }
    track('Checkout Step Completed', {
      currency: 'AUD',
      shippingMethod: shippingMethod?.description,
      step: 2,
      algolia_query_id: algoliaQueryId
    });
  };

  const handleBillingAddress = (key, value) => {
    const field = {};
    field[key] = value;
    const newFields = { ...billingAddress, ...field };
    setBillingAddress(newFields);
    storeCheckoutData({ billing: newFields });

    const validate = validateRequiredAddressFields(newFields);
    setBillingAddressValid(validate);
  };

  const assignBillingToOrder = newBillingFields => {
    if (!validateRequiredAddressFields(newBillingFields || billingAddress)) {
      // TODO: return meaningful error to user. This should be a formality here though as this is run as part of the shipping methods check.
      // console.log("Validation Billing failed")
      return false;
    }

    setBillingAddressValid(true);
    const cartId = cart.cartId;
    const billingData = newBillingFields || billingAddress;
    const requestBody = {
      email: checkoutEmail,
      first_name: billingData.first_name,
      last_name: billingData.last_name,
      company: billingData.company,
      address1: billingData.address1,
      address2: billingData.address2,
      city: billingData.city,
      country_code: billingData.country_code,
      state_or_province: billingData.state_or_province,
      state_or_province_code: billingData.state_or_province,
      postal_code: billingData.postal_code,
      phone: billingData.phone
    };

    bcApi(`checkouts/${cartId}/billing-address`, 'POST', requestBody).then(response => {
      if (response.status === 200) {
        toggleNextStep('payment');
      }
    });
  };

  const fetchDefaultStates = countryData => {
    const defaultCountry = countryData.find(country => country.node.country_iso2 === 'AU');
    if (defaultCountry) {
      const endpoint = defaultCountry.node.states.resource.substring(1);
      if (typeof window !== 'undefined') {
        bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(({ response, status }) => {
          if (status === 200) {
            setShippingCountryStates(response);
            setBillingCountryStates(response);
          } else {
            setShippingCountryStates([]);
            setBillingCountryStates([]);
          }
        });
      } else {
        setShippingCountryStates([]);
        setBillingCountryStates([]);
      }
    }
  };

  const fetchStates = (evt, type) => {
    const { value: countryIso } = evt.target;

    let countryObject = false;
    countries.map(country => {
      if (country.node.country_iso2 === countryIso) {
        countryObject = country;
      }
      return true;
    });
    setIsFetching(true);

    const endpoint = countryObject.node.states.resource.substring(1);
    bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(({ response, status }) => {
      if (status === 200) {
        if (type === 'shipping') {
          setShippingCountryStates(response);
        } else if (type === 'billing') {
          setBillingCountryStates(response);
        }
      } else {
        if (type === 'shipping') {
          setShippingCountryStates([]);
        } else if (type === 'billing') {
          setBillingCountryStates([]);
        }
      }
      setIsFetching(false);
    });
  };

  const validateCheckoutData = async evt => {
    evt.preventDefault();
    setConfirmingCheckoutData(true);
    setCheckoutFailMessage([]);

    await bcApi(`checkouts/${checkout.id}`).then(async response => {
      // console.log(response);
      if (response.status === 200 && 'data' in response.response) {
        const checkoutData = response.response.data;
        let isCheckoutValid = true;
        const failMessage = [];
        const errorList = [];
        // check if logged in user has customer ID set
        if (isLoggedIn && checkoutData.cart.customer_id === 0) {
          isCheckoutValid = false;
          errorList.push('logged in customer not set');
          failMessage.push('Logged in account is not assigned to the checkout - please refresh.');
        }
        // check for consignment set
        else if (
          (!checkoutData.consignments || checkoutData.consignments.length === 0) &&
          shippingRequired
        ) {
          isCheckoutValid = false;
          errorList.push('No consignment');
          failMessage.push(
            'No consignment found for the order. Please revisit step 3 and re-choose a shipping option.'
          );
        }
        // check for selected shipping option
        else if (
          checkoutData.consignments.length > 0 &&
          !('selected_shipping_option' in checkoutData.consignments[0]) &&
          shippingRequired
        ) {
          if (shippingMethod) {
            const reSubmitShippingOption = await handleShippingMethod(shippingMethod?.id);
            if (!reSubmitShippingOption || !'selected_shipping_option' in reSubmitShippingOption) {
              isCheckoutValid = false;
              failMessage.push(
                'No shipping option found for the order. Please revisit step 3 and re-choose a shipping option.'
              );
              errorList.push('No shipping option');
            }
          }
        }
        // check if shipping address is set
        if (
          checkoutData.consignments.length > 0 &&
          !('shipping_address' in checkoutData.consignments[0]) &&
          shippingRequired
        ) {
          isCheckoutValid = false;
          errorList.push('no shipping address');
          failMessage.push(
            'No shipping address found for the order. Please revisit step 3 and re-submit.'
          );
        }
        // check if set shipping address is valid
        else if (
          checkoutData.consignments.length > 0 &&
          !validateRequiredAddressFields(checkoutData.consignments[0].shipping_address) &&
          shippingRequired
        ) {
          isCheckoutValid = false;
          errorList.push('Shipping address invalid');
          failMessage.push(
            'The shipping address entered is invalid. Please return to step 3 and ensure all required fields are filled.'
          );
        }
        // check if billing address is set
        else if (!('billing_address' in checkoutData)) {
          isCheckoutValid = false;
          errorList.push('no billing address');
          failMessage.push(
            'No billing address found for the order. Please revisit step 4 and re-submit.'
          );
        }
        // check if set billing address is valid
        else if (!validateRequiredAddressFields(checkoutData.billing_address)) {
          isCheckoutValid = false;
          console.log('billing address not valid', checkoutData, checkoutData.billing_address);
          errorList.push('billing address not valid');
          failMessage.push(
            'The billing address entered is invalid. Please return to step 3 and ensure all required fields are filled.'
          );
        }

        if (!isCheckoutValid) {
          // TODO: Return meaningful feedback to user on failed check
          track('Checkout Error', {
            type: 'validateCheckoutData',
            msg: errorList.join(', '),
            response: checkoutData,
            shippingOptions: shippingOptions
          });
          setConfirmingCheckoutData(false);
          console.log('Checkout invalid returned');
          setCheckoutFailMessage(failMessage);
        } else {
          if (typeof window !== 'undefined') {
            track('Checkout Step Completed', {
              currency: 'AUD',
              shippingMethod: shippingMethod?.description,
              step: 3,
              algolia_query_id: algoliaQueryId
            });
            // window.location = '/checkout/';
            setStorage('__jamm_origin', 'checkout');
            window.location = '/checkout/';
            // navigate('/checkout');
            // dataLayerPush('Checkout', null, checkoutData, '/checkout/');
          }
        }
      } else {
        // TODO: Return meaningful feedback to user on failed check
        setConfirmingCheckoutData(false);
        setCheckoutFailMessage(['Something unexpected went wrong - please refresh the page.']);
      }
    });
  };

  useMemo(() => {
    const prevState = getStorage(stateKey);
    let changedState = false;
    if (prevState && cart && cartFetched) {
      const prevStateObj = JSON.parse(prevState);
      // console.log("Cart Fetched", cartFetched);
      // console.log("BaseAmount", prevStateObj.baseAmount, cart.baseAmount);
      // console.log("Items", prevStateObj.items, cart.numberItems);
      if (prevStateObj.baseAmount !== cart.baseAmount || prevStateObj.items !== cart.numberItems) {
        // console.log("Set to change back to cart", prevStateObj.baseAmount !== cart.baseAmount, prevStateObj.items !== cart.numberItems);
        changedState = true;
      }
    }
    if (
      cart &&
      cart.lineItems &&
      'physical_items' in cart.lineItems &&
      cart.lineItems.physical_items.length === 0 &&
      'gift_certificates' in cart.lineItems &&
      cart.lineItems.gift_certificates.length > 0 &&
      shippingRequired === true
    ) {
      // DIGITAL ITEMS IN CART ONLY - No need for shipping
      setShippingRequired(false);

      if (currentStep !== 'cart' && cartShippingMethod === 'collect') {
        // console.log("Digital only - back to cart");
        setCurrentStep('cart');
        setCartStep(true);
        setStorage(stepKey, 'cart', true);
      }
    }

    const storedCheckoutJSON = getStorage(storeKey) || JSON.stringify({});
    const storedCheckout = JSON.parse(storedCheckoutJSON);
    if (
      ((Object.keys(storedCheckout).length === 0 || changedState) && currentStep !== 'cart') ||
      (currentStep === 'account' && isLoggedIn)
    ) {
      // console.log("reset to cart", currentStep, storedCheckout, changedState);
      setCurrentStep('cart');
      setCartStep(true);
      setStorage(stepKey, 'cart', true);

      // removeCheckoutData('cartConfirmed');
    }

    if (!init) {
      if ('email' in storedCheckout) {
        setCheckoutEmail(storedCheckout.email);
      }
      if ('shipping' in storedCheckout) {
        setShippingAddress(storedCheckout.shipping);
      }
      if ('shippingMethod' in storedCheckout) {
        setShippingMethod(storedCheckout.shippingMethod);
      }
      if ('billing' in storedCheckout) {
        setBillingAddress(storedCheckout.billing);
        setBillingAddressValid(true);
      }
      if (countries.length === 0) {
        setCountries(dataQuery.allBigCommerceCountries.edges);
        fetchDefaultStates(dataQuery.allBigCommerceCountries.edges);
      }
      if (currentStep === 'shipping' || currentStep === 'billing') {
        refreshUser();
      }
      setInit(true);
    }

    if (!allProductsAvailable) {
      setAvailable(true);
    }
  }, [
    init,
    cart,
    cartFetched,
    currentStep,
    dataQuery,
    countries,
    refreshUser,
    isLoggedIn,
    shippingRequired,
    cartShippingMethod,
    allProductsAvailable
  ]); //, ctxStockAvailability, ctxSelectedStore

  useMemo(() => {
    const confirmAccountAssigned = async () => {
      if (
        !fetchingCheckout &&
        currentStep !== 'cart' &&
        checkout &&
        'cart' in checkout &&
        checkout.cart.customer_id === 0 &&
        isLoggedIn
      ) {
        setFetchingCheckout(true);
        // console.log("Found logged in, but account not assigned in checkout");
        // console.log("Attempt to set...");
        await bcApi(`carts/${checkout.id}`, 'PUT', {
          customer_id: accountDetails.id
        });

        // console.log("Refresh checkout state");
        const latestCheckout = await ctxReloadCheckout();
        // console.log(latestCheckout);
        if (
          latestCheckout &&
          'cart' in latestCheckout.checkout &&
          latestCheckout.checkout.cart.customer_id === 0 &&
          isLoggedIn
        ) {
          // console.log("Resetting back to cart because the cart doesn't have the customer ID assigned");
          // console.log("Checkout", checkout);
          setCurrentStep('cart');
          setCartStep(true);
          setCheckoutEmail(null);
          removeCheckoutData('email');
          setStorage(stepKey, 'cart', true);
          // } else {
          //     console.log("All good - continue");
        }
        setFetchingCheckout(false);
      }
    };
    confirmAccountAssigned();
  }, [checkout, isLoggedIn, ctxReloadCheckout, fetchingCheckout, currentStep, accountDetails]);

  //This will prevent refetching when the checkbox of billingSameAsShipping is changed
  const watchChangedValues = useMemo(() => {
    const { billingSameAsShipping, ...rest } = debouncedShipping;
    return JSON.stringify(rest);
  }, [debouncedShipping]);

  // main observer for shipping address changes
  useEffect(() => {
    if (currentStep === 'shipping' && !isFetching && !settingConsignment) {
      checkForShippingMethods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, isFetching, watchChangedValues]);

  // this is a one time trigger to check for shipping methods when the cart is loaded
  useEffect(() => {
    if (cart.lineItems?.physical_items?.length > 0 && !deliveryInit && currentStep === 'shipping') {
      checkForShippingMethods();
      setDeliveryInit(true);
    }
  }, [cart.lineItems, deliveryInit, currentStep]);

  // this is a workaround to update the state as google autocomplete uses a ref to old state
  // doing this will trigger the change using the new state
  useEffect(() => {
    if (
      (currentStep === 'shipping' || currentStep === 'billing') &&
      place.place &&
      place.onDemand
    ) {
      placeSelectHandler(place.place, place.billingOnly);
    }
  }, [place]);

  //observer for checkout errors
  useEffect(() => {
    const showErrorMessage = () => {
      let message = '';
      if (checkoutError === 's1') {
        message = 'Please confirm your email address is correct and try again.';
      } else if (checkoutError === 's2') {
        message =
          'There was an error with your shipping address. Please check your details and try again.';
      } else if (checkoutError === 's3') {
        message =
          'There was an error with your billing address. Please check your details and try again.';
      } else if (checkoutError === 's0') {
        toggleNextStep('cart');
      }
      if (message) {
        toast({
          title: 'Incomplete details',
          description: message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
          id: 'checkout-error'
        });
      }
    };

    const availableStep = ['shipping', 'billing', 'payment', 'cart', 'account'];
    if (triggerStep && availableStep.includes(triggerStep)) {
      toggleNextStep(triggerStep);
    }
    if (checkoutError) showErrorMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutError, triggerStep]);

  const shippingContinueDisable =
    !shippingMethod ||
    methodBeingSet ||
    fetchingOptions ||
    signature ||
    shippingOptions.length === 0;

  const classNameFieldRequire = field => {
    const notHaveShippingMethod = shippingOptions.length === 0 &&!fetchingOptions && fetchedOptions;
    const emptyField = !shippingAddress[field] || shippingAddress[field].length === 0;
    const displayError = fieldMissing.includes(field) && emptyField && notHaveShippingMethod;
    return `formField required ${displayError ? styles.fieldError : ''}`;
  };

  const guestCheckoutHTML = () => {
    return (
      <>
        <Flex
          alignItems={'center'}
          mb={5}
          _hover={{
            color: '#1D6464',
            textDecorationColor: '#1D6464 !important',
            cursor: 'pointer'
          }}
          onClick={() => toggleNextStep('cart')}
        >
          <Icon symbol={'chevLeft'} />
          <Text
            color={isMadPawsStore() ? 'var(--primary-default)' : ''}
            fontSize={16}
            ml={3}
            className="font-medium underline"
          >
            Edit Cart
          </Text>
        </Flex>
        <Box border={'1px solid var(--grey-border)'} p={4} borderRadius={8}>
          <Text className={styles.stepTitle} mb={2}>
            Checkout
          </Text>
          <div className={styles.checkoutOptionText}>Have an account?</div>
          <div className={styles.checkoutOptionText}>
            Sign in to securely check out faster or continue as a guest.
          </div>
          <SimpleGrid gap={{ base: '28px' }} pt="15px">
            <Grid templateColumns="1fr 1fr" gap={4} color={'#fff'}>
              <Button href={'/login'} level={'primary'} className={styles.guestPopUpColor}>
                SIGN IN
              </Button>
              <Button href={'/signup'} level={'greyBtn'}>
                CREATE ACCOUNT
              </Button>
            </Grid>
          </SimpleGrid>
        </Box>
        <Box mt="15px" border={'1px solid var(--grey-border)'} px="4" py="18px" borderRadius={8}>
          <SimpleGrid gap={{ base: '28px' }}>
            <Flex flexDirection={'column'}>
              <Text mb="15px" className={styles.stepTitle}>
                Guest Checkout
              </Text>
              <Text className={styles.checkoutOptionText}>
                Enter your email to continue as a guest
              </Text>
              <Input
                placeholder="Email Address"
                padding={'14px'}
                mt={'9px'}
                type="email"
                width={'100%'}
                defaultValue={checkoutEmail}
                onChange={e => handleGuestEmail(e.target.value)}
                _focus={{
                  boxShadow: 'none',
                  border: 'none !important'
                }}
              />
              <div className={`${styles.guestEmailOptions}`}>
                {!isAutoShip ? (
                  <div>
                    <Checkbox
                      value={subscribe}
                      action={setSubscribe}
                      id="newsletter-checkbox"
                      label="Subscribe to our newsletter for exclusive weekly discounts."
                    />
                  </div>
                ) : (
                  <p className={styles.loginRequired}>
                    You must be signed into an account to place an order with AutoShip subscription
                    products
                  </p>
                )}
              </div>
              <Box mt={'10px'}>
                <Button
                  level={'greyBtn'}
                  disabled={!checkoutEmail}
                  onClick={() => assignGuestToOrder()}
                >
                  CHECKOUT AS GUEST
                </Button>
              </Box>
            </Flex>
          </SimpleGrid>
        </Box>
        {/* <Modal isOpen={openLogin} onClose={() => setOpenLogin(false)} isCentered>
          <ModalOverlay />
          <ModalContent borderRadius={{ base: 0, lg: '12px' }}>
            <ModalBody p="0" className={styles.loginModal}>
              <LoginForm
                isLoggedIn={isLoggedIn && userChecked}
                handleLogin={handleLogin}
                customWarning="This email is already registered. Please enter your password to proceed."
                emailLogin={checkoutEmail}
                isLoginPage={false}
              />
            </ModalBody>
          </ModalContent>
        </Modal> */}
      </>
    );
  };

  return (
    <Layout className={`${styles.root} ${currentStep !== 'cart' ? styles.checkout : ''}`} slim>
      {/* <input type="text" ref={searchRef} /> */}
      <Seo title="Confirm your order" />
      <Container size="large">
        {!cartFetched && !methodBeingSet && (
          <div className={styles.bcConfirmOrderContainerEmpty}>
            <div className={styles.bcCartTitle}>
              <h1 className="h3 text-left">Cart</h1>
            </div>
            <div className={styles.bcCartEmpty}>
              <div className="text-center">
                <Spinner size={'xl'} color="teal" />
                <p className="subtitle text-center" style={{ paddingTop: '40px' }}>
                  Loading cart...
                </p>
              </div>
            </div>
          </div>
        )}
        {cartFetched && !numberItems && (
          <div className={styles.bcConfirmOrderContainer}>
            <div className={styles.bcCartTitle}>
              <h1 className="h3 text-left">Cart</h1>
              <Link url="/" className={styles.continueShopping}>
                continue shopping
              </Link>
            </div>
            <div className={styles.bcCartEmpty}>
              <div className="text-center">
                <p className="subtitle text-center">Your cart is empty.</p>
                <Link url="/">Take a look around.</Link>
              </div>
            </div>
          </div>
        )}
        {((cartFetched && !!numberItems) || (!!numberItems && methodBeingSet)) && (
          <div name="section-cart" id="section-cart">
            <Box
              className={clsx('grid-7030', styles.bcConfirmOrderContainer)}
              gap={currentStep !== 'cart' ? '90' : 8}
            >
              <Box
                bg={currentStep === 'cart' ? '' : 'white'}
                className={currentStep === 'cart' ? '' : styles.checkoutSteps}
              >
                {currentStep === 'cart' && (
                  <>
                    <div className={styles.bcCartTitle}>
                      <h1 className="h3 text-left">My Cart</h1>
                    </div>
                    <div>
                      {cart?.frequencyOptions && cart?.frequencyOptions.length > 0 && (
                        <AutoShip step={currentStep} />
                      )}
                      {ccEligible && (
                        <CartShippingOptions
                          isValid={res => setIsValid(res)}
                          setShippingAddress={setShippingAddress}
                          setShippingOptions={setShippingOptions}
                        />
                      )}
                      <Cart cartType="full" />
                    </div>
                  </>
                )}
                {currentStep !== 'cart' && (
                  <>
                    <div
                      className={`${styles.step} ${styles.checkout}`}
                      id="section-account"
                      name="section-account"
                    >
                      <div className={styles.stepHeader}>
                        {(currentStep === 'shipping' ||
                          currentStep === 'billing' ||
                          currentStep === 'payment') && (
                          <>
                            <span className={styles.stepTitle}>Customer</span>
                            {
                              <>
                                <span className={styles.setValue}>{checkoutEmail}</span>
                                {!confirmingCheckoutData && (
                                  <>
                                    {!isLoggedIn ? (
                                      <span
                                        className={`${styles.stepAction} link fancy lightLink`}
                                        role="presentation"
                                        onClick={() => toggleNextStep('account')}
                                      >
                                        Edit
                                      </span>
                                    ) : (
                                      <a
                                        className={`${styles.stepAction} link fancy lightLink`}
                                        href="/logout"
                                      >
                                        Logout
                                      </a>
                                    )}
                                  </>
                                )}
                              </>
                            }
                          </>
                        )}
                      </div>
                      {currentStep === 'account' && <>{guestCheckoutHTML()}</>}
                    </div>

                    {shippingRequired && (
                      <div className={styles.step} id="section-shipping" name="section-shipping">
                        <div className={styles.stepHeader}>
                          <span
                            className={`${styles.stepNumber} ${
                              currentStep === 'billing' || currentStep === 'payment'
                                ? styles.completed
                                : ''
                            }`}
                          ></span>
                          <span className={styles.stepTitle}>
                            {cartShippingMethod === 'collect' ? 'Pickup' : 'Shipping'}
                          </span>

                          {cartShippingMethod === 'collect' &&
                            (currentStep === 'billing' || currentStep === 'payment') && (
                              <>
                                <span className={styles.setValue}>
                                  {shippingAddress && shippingAddress.first_name}
                                  <br />
                                  {shippingAddress &&
                                    [
                                      shippingAddress.address1,
                                      shippingAddress.city,
                                      shippingAddress.postal_code,
                                      shippingAddress.state_or_province
                                    ].join(', ')}
                                </span>
                              </>
                            )}
                          {cartShippingMethod !== 'collect' &&
                            (currentStep === 'billing' || currentStep === 'payment') && (
                              <>
                                <span className={styles.setValue}>
                                  {shippingAddress && shippingAddress.first_name}{' '}
                                  {shippingAddress && shippingAddress.last_name}
                                  <br />
                                  {shippingAddress && shippingAddress.address1}
                                </span>
                              </>
                            )}
                          {cartShippingMethod !== 'collect' &&
                            !confirmingCheckoutData &&
                            ['billing', 'payment'].indexOf(currentStep) > -1 && (
                              <span
                                className={`${styles.stepAction} link fancy lightLink`}
                                role="presentation"
                                onClick={() => toggleNextStep('shipping')}
                              >
                                Edit
                              </span>
                            )}
                        </div>
                        {currentStep === 'shipping' && (
                          <div className={styles.stepBody}>
                            {accountDetails &&
                              'addresses' in accountDetails &&
                              accountDetails.addresses.length > 0 && (
                                <>
                                  <div className={`flex-between ${styles.formFields}`}>
                                    <div className="formField">
                                      <label htmlFor="shipping_preselect_address">
                                        Select previously used address
                                      </label>
                                      <select
                                        id="shipping_preselect_address"
                                        onBlur={() => {}}
                                        onChange={e =>
                                          setPreviousAddress('shipping', e.target.value)
                                        }
                                      >
                                        <option selected disabled>
                                          Select an address...
                                        </option>
                                        {accountDetails.addresses.map((address, addressIndex) => (
                                          <option key={addressIndex} value={address.id}>
                                            {address.address1}
                                            {address.address2 !== '' ? `, ${address.address2}` : ''}
                                            , {address.city} {address.state_or_province}{' '}
                                            {address.postal_code} {address.country}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </>
                              )}
                            <div className={`flex-between ${styles.formFields}`}>
                              <div className={classNameFieldRequire('first_name')}>
                                <label>First name</label>
                                <Input
                                  placeholder=" First name"
                                  type="text"
                                  id="shipping_first_name"
                                  class
                                  defaultValue={shippingAddress.first_name}
                                  value={shippingAddress.first_name}
                                  onInput={e => handleShippingAddress('first_name', e.target.value)}
                                />
                              </div>
                              <div className={classNameFieldRequire('last_name')}>
                                <label>Last name</label>
                                <Input
                                  type="text"
                                  placeholder="  Last name"
                                  id="shipping_last_name"
                                  defaultValue={shippingAddress.last_name}
                                  value={shippingAddress.last_name}
                                  onInput={e => handleShippingAddress('last_name', e.target.value)}
                                />
                              </div>
                            </div>

                            <div className={`flex-between ${styles.formFields}`}>
                              <div className={classNameFieldRequire('phone')}>
                                <label>Phone number</label>
                                <Input
                                  placeholder="Phone number"
                                  type="text"
                                  id="shipping_phone"
                                  maxLength={13}
                                  defaultValue={shippingAddress.phone}
                                  value={shippingAddress.phone}
                                  onInput={e => handleShippingAddress('phone', e.target.value)}
                                />
                              </div>
                            </div>
                            <div className={`flex-between ${styles.formFields}`}>
                              <div className="formField">
                                <label>Company/Parcel Locker</label>
                                <Input
                                  placeholder="Company/Parcel Locker (Optional)"
                                  type="text"
                                  id="shipping_company"
                                  defaultValue={shippingAddress.company}
                                  onInput={e => handleShippingAddress('company', e.target.value)}
                                />
                              </div>
                            </div>
                            <div className={`flex-between ${styles.formFields}`}>
                              <div className={classNameFieldRequire('country_code')}>
                                <label>Country</label>
                                <Select
                                  id="shipping_country_code"
                                  //disabled={!manualShippingAddress}
                                  defaultValue={shippingAddress.country_code || 'AU'}
                                  value={shippingAddress.country_code || 'AU'}
                                  onInput={e => {
                                    handleShippingAddress('country_code', e.target.value);

                                    fetchStates(e, 'shipping');
                                  }}
                                >
                                  <option disabled>Country</option>
                                  {countries.map((country, countryIndex) => (
                                    <option key={countryIndex} value={country.node.country_iso2}>
                                      {country.node.country}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className={`flex-between ${styles.formFields}`}>
                              <div className={classNameFieldRequire('address1')}>
                                <label>Address</label>
                                <Autocomplete
                                  placeholder="Address"
                                  options={{
                                    types: ['address'],
                                    componentRestrictions: {
                                      country: shippingAddress?.country_code ?? ''
                                    }
                                  }}
                                  apiKey={`AIzaSyCvjuRiZifN-z2tETeygWTKJy8veUP-VAw`}
                                  onPlaceSelected={place =>
                                    setPlace({
                                      place,
                                      billingOnly: false,
                                      onDemand: true
                                    })
                                  }
                                  ref={searchRef}
                                  type="text"
                                  id="shipping_address1"
                                  //disabled={!manualShippingAddress}
                                  defaultValue={shippingAddress.address1}
                                  value={shippingAddress.address1}
                                  onInput={e => {
                                    handleShippingAddress('address1', e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div className={`flex-between ${styles.formFields}`}>
                              <div className={classNameFieldRequire('city')}>
                                <label>Suburb</label>
                                <Input
                                  type="text"
                                  placeholder="Suburb"
                                  id="shipping_city"
                                  //disabled={!manualShippingAddress}
                                  defaultValue={shippingAddress.city}
                                  value={shippingAddress.city}
                                  onInput={e => handleShippingAddress('city', e.target.value)}
                                />
                              </div>
                            </div>
                            <Flex className={`${styles.formFields}`}>
                              <div className={classNameFieldRequire('state_or_province')}>
                                <label value="">State</label>
                                {!isEmpty(shippingCountryStates) && (
                                  <Select
                                    id="shipping_state"
                                    //disabled={!manualShippingAddress}
                                    defaultValue={shippingAddress.state_or_province}
                                    value={shippingAddress?.state_or_province ?? ''}
                                    onInput={e => {
                                      handleShippingAddress('state_or_province', e.target.value);
                                    }}
                                  >
                                    <option>State</option>
                                    {shippingCountryStates.map((state, stateIndex) => (
                                      <option key={stateIndex} value={state.state}>
                                        {state.state}
                                      </option>
                                    ))}
                                  </Select>
                                )}
                                {isEmpty(shippingCountryStates) && (
                                  <Input
                                    type="text"
                                    id="shipping_state"
                                    //disabled={!manualShippingAddress}
                                    defaultValue={shippingAddress.state_or_province}
                                    onInput={e =>
                                      handleShippingAddress('state_or_province', e.target.value)
                                    }
                                  />
                                )}
                                {isFetching && (
                                  <div className={styles.spinnerWrapper}>
                                    <Loader />
                                  </div>
                                )}
                              </div>
                              <Box
                                maxW={{
                                  base: '100%',
                                  md: 202
                                }}
                                className={classNameFieldRequire('postal_code')}
                              >
                                <label>Postcode</label>
                                <Input
                                  type="text"
                                  placeholder="Postcode"
                                  id="shipping_postal_code"
                                  //disabled={!manualShippingAddress}
                                  defaultValue={shippingAddress.postal_code}
                                  value={shippingAddress.postal_code}
                                  onInput={e =>
                                    handleShippingAddress('postal_code', e.target.value)
                                  }
                                />
                              </Box>
                            </Flex>
                            <div className={`flex-between ${styles.formFields}`}>
                              <div className="formField">
                                <Checkbox
                                  value={true}
                                  isChecked={shippingAddress.billingSameAsShipping}
                                  action={handleShippingToBilling}
                                  label=" Billing address same as shipping"
                                />
                              </div>
                            </div>
                            <div className={`flex-between ${styles.formFields}`}>
                              <div className="formField ">
                                <label htmlFor="shipping_methods">Delivery</label>

                                <div id="shipping_methods">
                                  {shippingOptions.length === 0 &&
                                    !fetchingOptions &&
                                    !fetchedOptions && <span>{shippingOptionMessage}</span>}
                                  {(fetchingOptions || settingConsignment) && (
                                    <span>Fetching shipping options, please wait...</span>
                                  )}
                                  {shippingOptions.length === 0 &&
                                    !fetchingOptions &&
                                    fetchedOptions && (
                                      <span>
                                        Unfortunately one or more items in your cart can't be
                                        shipped to your location. Please choose a different delivery
                                        address.
                                      </span>
                                    )}
                                  {shippingOptions.length > 0 &&
                                    !fetchingOptions &&
                                    fetchedOptions &&
                                    !settingConsignment && (
                                      <div className={styles.shippingOptions}>
                                        {shippingOptions.map((option, optionIndex) => (
                                          <section
                                            key={optionIndex}
                                            className={`shipping-option ${
                                              shippingMethod
                                                ? shippingMethod.id === option.id
                                                  ? 'active'
                                                  : ''
                                                : ''
                                            }`}
                                            role="button"
                                            onClick={() => handleShippingMethod(option.id)}
                                          >
                                            <div className="details">
                                              <div className="marker">
                                                <div className="marker-thumb"></div>
                                              </div>
                                              <p className="name">
                                                {rewriteShippingOptionLabel(
                                                  option.description,
                                                  true
                                                )}
                                              </p>
                                            </div>

                                            <p className="price">
                                              <CurrencyFormatter
                                                amount={option.cost}
                                                currency="AUD"
                                              />
                                            </p>
                                          </section>
                                        ))}
                                      </div>
                                    )}
                                  {(methodBeingSet || settingConsignment) && (
                                    <div className={styles.loadingShipping}>
                                      <Loader />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {!hasComment ? (
                              <ChakraLink
                                onClick={onComment}
                                color={isMadPawsStore() ? 'var(--primary-default)' : '#14607A'}
                                textDecor={'underline'}
                              >
                                Add delivery notes
                              </ChakraLink>
                            ) : (
                              <div className={`flex-between ${styles.formFields}`}>
                                <Box className="formField" m={'0!important'}>
                                  <Textarea
                                    placeholder="Comments (Optional)"
                                    id="shipping_comments"
                                    defaultValue={shippingAddress.comments}
                                    onChange={e =>
                                      handleShippingAddress('comments', e.target.value)
                                    }
                                  />
                                </Box>
                              </div>
                            )}
                            <Box mt={8}>
                              <Button
                                level="primary"
                                type="span"
                                className={styles.continueButton}
                                disabled={shippingContinueDisable}
                                onClick={() => assignShippingToOrder()}
                              >
                                Continue
                              </Button>
                            </Box>
                          </div>
                        )}
                      </div>
                    )}

                    <div className={styles.step} id="section-billing" name="section-billing">
                      <div className={styles.stepHeader}>
                        <span
                          className={`${styles.stepNumber} ${
                            currentStep === 'payment' ? styles.completed : ''
                          }`}
                        >
                          {/* {shippingRequired ? '4' : '3'} */}
                        </span>
                        <span className={styles.stepTitle}>Billing</span>
                        {currentStep === 'payment' && (
                          <>
                            <span className={styles.setValue}>
                              {billingAddress && billingAddress.first_name}{' '}
                              {billingAddress && billingAddress.last_name}
                              <br />
                              {billingAddress && billingAddress.address1}
                            </span>
                            {!confirmingCheckoutData && (
                              <span
                                className={`${styles.stepAction} link fancy lightLink`}
                                role="presentation"
                                onClick={() => toggleNextStep('billing')}
                              >
                                Edit
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      {currentStep === 'billing' && (
                        <div className={styles.stepBody}>
                          {accountDetails &&
                            'addresses' in accountDetails &&
                            accountDetails.addresses.length > 0 && (
                              <>
                                <div className={`flex-between ${styles.formFields}`}>
                                  <div className="formField">
                                    <label htmlFor="billing_preselect_address">
                                      Select previously used address
                                    </label>
                                    <Select
                                      id="billing_preselect_address"
                                      onBlur={() => {}}
                                      onChange={e => setPreviousAddress('billing', e.target.value)}
                                    >
                                      <option selected disabled>
                                        Select an address...
                                      </option>
                                      {accountDetails.addresses.map((address, addressIndex) => (
                                        <option key={addressIndex} value={address.id}>
                                          {address.address1}
                                          {address.address2 !== ''
                                            ? `, ${address.address2}`
                                            : ''}, {address.city} {address.state_or_province}{' '}
                                          {address.postal_code} {address.country}
                                        </option>
                                      ))}
                                    </Select>
                                  </div>
                                </div>
                              </>
                            )}
                          <div className={`flex-between ${styles.formFields}`}>
                            <div className="formField required">
                              <label>First name</label>
                              <Input
                                placeholder="First Name"
                                type="text"
                                id="billing_first_name"
                                defaultValue={billingAddress.first_name}
                                onInput={e => handleBillingAddress('first_name', e.target.value)}
                              />
                            </div>
                            <div className="formField required">
                              <label>Last name</label>
                              <Input
                                placeholder="Last Name"
                                type="text"
                                id="billing_last_name"
                                defaultValue={billingAddress.last_name}
                                onInput={e => handleBillingAddress('last_name', e.target.value)}
                              />
                            </div>
                          </div>

                          <div className={`flex-between ${styles.formFields}`}>
                            <div className="formField required">
                              <label>Phone number</label>
                              <input
                                maxLength={13}
                                type="text"
                                placeholder="Phone number"
                                id="billing_phone"
                                defaultValue={billingAddress.phone}
                                onInput={e => handleBillingAddress('phone', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className={`flex-between ${styles.formFields}`}>
                            <div className="formField">
                              <label>Company/Parcel Locker</label>
                              <Input
                                placeholder="Company/Parcel Locker (Optional)"
                                type="text"
                                id="billing_company"
                                defaultValue={billingAddress.company}
                                onChange={e => handleBillingAddress('company', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className={`flex-between ${styles.formFields}`}>
                            <div className="formField required">
                              <label>Country</label>
                              <Select
                                id="billing_country_code"
                                // disabled={!manualBillingAddress}
                                defaultValue={billingAddress.country_code || 'AU'}
                                onBlur={e => handleBillingAddress('country_code', e.target.value)}
                                onInput={e => fetchStates(e, 'billing')}
                              >
                                <option disabled>Country</option>
                                {countries.map((country, countryIndex) => (
                                  <option key={countryIndex} value={country.node.country_iso2}>
                                    {country.node.country}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className={`flex-between ${styles.formFields}`}>
                            <div className="formField required">
                              <label>Address</label>
                              <Input
                                as={Autocomplete}
                                placeholder="Address"
                                apiKey={`AIzaSyCvjuRiZifN-z2tETeygWTKJy8veUP-VAw`}
                                onPlaceSelected={place =>
                                  setPlace({
                                    place,
                                    billingOnly: true,
                                    onDemand: true
                                  })
                                }
                                options={{
                                  types: ['address'],
                                  componentRestrictions: {
                                    country: billingAddress?.country_code ?? ''
                                  }
                                }}
                                type="text"
                                id="billing_address1"
                                //disabled={!manualShippingAddress}
                                defaultValue={billingAddress.address1}
                                value={billingAddress.address1}
                                onInput={e => {
                                  handleBillingAddress('address1', e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className={`flex-between ${styles.formFields}`}>
                            <div className="formField">
                              <label>Apartment/Suite/Building</label>
                              <Input
                                placeholder="Apartment/Suite/Building (Optional)"
                                type="text"
                                id="billing_address2"
                                // disabled={!manualBillingAddress}
                                defaultValue={billingAddress.address2}
                                onInput={e => handleBillingAddress('address2', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className={`flex-between ${styles.formFields}`}>
                            <div className="formField required">
                              <label>Suburb</label>
                              <Input
                                type="text"
                                placeholder="Suburb"
                                id="billing_city"
                                // disabled={!manualBillingAddress}
                                defaultValue={billingAddress.city}
                                onInput={e => handleBillingAddress('city', e.target.value)}
                              />
                            </div>
                          </div>

                          <div className={`flex-between ${styles.formFields}`}>
                            <div className="formField required">
                              <label>State</label>
                              {!isEmpty(billingCountryStates) && (
                                <Select
                                  id="billing_state"
                                  // disabled={!manualBillingAddress}
                                  defaultValue={billingAddress.state_or_province}
                                  onInput={e =>
                                    handleBillingAddress('state_or_province', e.target.value)
                                  }
                                >
                                  <option value={''}>State</option>
                                  {billingCountryStates.map((state, stateIndex) => (
                                    <option key={stateIndex} value={state.state}>
                                      {state.state}
                                    </option>
                                  ))}
                                </Select>
                              )}
                              {isEmpty(billingCountryStates) && (
                                <Input
                                  placeholder="State"
                                  type="text"
                                  id="billing_state"
                                  // disabled={!manualBillingAddress}
                                  defaultValue={billingAddress.state_or_province}
                                  value={billingAddress?.state_or_province ?? ''}
                                  onInput={e =>
                                    handleBillingAddress('state_or_province', e.target.value)
                                  }
                                />
                              )}
                              {isFetching && (
                                <div className={styles.spinnerWrapper}>
                                  <Loader />
                                </div>
                              )}
                            </div>
                            <Box maxW={202} className="formField required">
                              <label>Postcode</label>
                              <Input
                                placeholder="Postcode"
                                type="text"
                                id="billing_postal_code"
                                // disabled={!manualBillingAddress}
                                defaultValue={billingAddress.postal_code}
                                onInput={e => handleBillingAddress('postal_code', e.target.value)}
                              />
                            </Box>
                          </div>
                          <Button
                            level="primary"
                            type="button"
                            disabled={!isBillingAddressValid}
                            onClick={() => assignBillingToOrder()}
                            className={styles.full}
                          >
                            Continue
                          </Button>
                        </div>
                      )}
                    </div>

                    <div className={styles.step} id="section-payment" name="section-payment">
                      <div className={styles.stepHeader}>
                        <span className={styles.stepNumber}>
                          {/* {shippingRequired ? '5' : '4'} */}
                        </span>
                        <span className={styles.stepTitle}>Confirm order</span>
                      </div>
                      {currentStep === 'payment' && (
                        <div className={styles.stepBody}>
                          <div className={styles.customerSummary}>
                            <strong>Customer: </strong>
                            {checkoutEmail}
                          </div>
                          <div className={`flex-between ${styles.addressSummary}`}>
                            {shippingRequired && (
                              <div className={styles.shippingSummary}>
                                <strong>
                                  {cartShippingMethod === 'collect' ? 'Collection' : 'Shipping'}:
                                </strong>
                                <br />
                                {shippingAddress && shippingAddress.first_name}{' '}
                                {shippingAddress && shippingAddress.last_name}
                                <br />
                                {shippingAddress &&
                                  shippingAddress.company &&
                                  shippingAddress.company !== '' && (
                                    <>
                                      {shippingAddress.company}
                                      <br />
                                    </>
                                  )}
                                {shippingAddress && shippingAddress.address1}
                                <br />
                                {shippingAddress && shippingAddress.city}
                                <br />
                                {shippingAddress && shippingAddress.state_or_province},{' '}
                                {shippingAddress && shippingAddress.postal_code}
                                <br />
                                {shippingAddress &&
                                  countries &&
                                  countries.find(
                                    country =>
                                      country.node.country_iso2 === shippingAddress.country_code
                                  )?.node.country}
                                <br />
                                {shippingAddress && shippingAddress.phone}
                                <br />
                                {shippingAddress &&
                                  shippingAddress.comments &&
                                  shippingAddress.comments !== '' && (
                                    <>
                                      <br />
                                      {shippingAddress.comments}
                                    </>
                                  )}
                              </div>
                            )}
                            <div className={styles.billingSummary}>
                              <strong>Billing:</strong>
                              <br />
                              {billingAddress && billingAddress.first_name}{' '}
                              {billingAddress && billingAddress.last_name}
                              <br />
                              {billingAddress &&
                                billingAddress.company &&
                                billingAddress.company !== '' && (
                                  <>
                                    {billingAddress.company}
                                    <br />
                                  </>
                                )}
                              {billingAddress && billingAddress.address1}
                              <br />
                              {billingAddress &&
                                billingAddress.address2 &&
                                billingAddress.address2 !== '' && (
                                  <>
                                    {billingAddress.address2}
                                    <br />
                                  </>
                                )}
                              {billingAddress && billingAddress.city}
                              <br />
                              {billingAddress && billingAddress.state_or_province},{' '}
                              {billingAddress && billingAddress.postal_code}
                              <br />
                              {billingAddress &&
                                countries &&
                                countries.find(
                                  country =>
                                    country.node.country_iso2 === billingAddress.country_code
                                )?.node.country}
                              <br />
                              {billingAddress && billingAddress.phone}
                            </div>
                          </div>
                          <div className={styles.shippingMethodSummary}>
                            <strong>Shipping Method: </strong>
                            {shippingRequired && (
                              <>
                                {cartShippingMethod === 'collect' && (
                                  <>
                                    Store pickup - <CurrencyFormatter amount={0} currency="AUD" />
                                  </>
                                )}
                                {cartShippingMethod !== 'collect' && (
                                  <>
                                    {shippingMethod.description} -{' '}
                                    <CurrencyFormatter
                                      amount={shippingMethod.cost}
                                      currency="AUD"
                                    />
                                  </>
                                )}
                              </>
                            )}
                            {!shippingRequired && <>Email</>}
                          </div>
                          {checkoutFailMessage && checkoutFailMessage.length > 0 && (
                            <div className={styles.errorMessages}>
                              {checkoutFailMessage.map((msg, msgIndex) => (
                                <span key={msgIndex}>{msg}</span>
                              ))}
                            </div>
                          )}
                          <Button
                            level="primary"
                            type="button"
                            disabled={confirmingCheckoutData}
                            className={styles.continueButton}
                            onClick={validateCheckoutData}
                          >
                            {confirmingCheckoutData ? 'Please wait...' : 'Proceed to Payment'}
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Box>
              <CartSummary
                isCheckout={currentStep !== 'cart'}
                cartType="full"
                nextStepValid={nextStepValid && allProductsAvailable}
                cartStep={cartStep}
                cartFunc={toggleNextStep}
              />
            </Box>
          </div>
        )}

        {cartFetched && !!numberItems && currentStep === 'cart' && recommendations?.length > 0 && (
          <Box mt={20} pb={105}>
            <Box
              as="h3"
              fontSize={{
                base: 22,
                md: 32
              }}
              pb={8}
              className={styles.recommend}
            >
              Recommended for you
            </Box>
            <FrequentProducts products={recommendations} />
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default ConfirmOrderPage;
